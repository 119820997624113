.wrapper{
  width: 600px;
  height: 600px;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.title{
  font-size: 156px;
}

.subtext{
  font-size: 24px;
}