@use "../../variables" as v;

.heading {
  text-align: center;
  margin: 30px 0;
}

.inputs {
  display: flex;
}

.input {
  margin: 0 10px;
  border: 1px solid #fafafa;
  height: 200px;
  font-family: v.$font-family;
  display: block;
  width: calc(100% - 33px);
  color: v.$light;
  background-color: v.$dark;
  border-radius: 6px;
  outline: none;
  font-size: 1rem;
  padding: 8px 16px;
  box-shadow: none;
  cursor: text;

  &:focus {
    border: 1.5px solid v.$primary-color;
  }

  &::placeholder {
    font-family: v.$font-family;
  }
}

.button {
  margin-top: 10px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  word-break: break-all;
  Line-height: 1.5em;
  padding: 20px 10px 10px 10px;
}

@media (max-width: 500px) {
  .inputs{
    flex-direction: column;
  }

  .input{
    margin-bottom: 20px;
  }
}