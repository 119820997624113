.heading{
    text-align: center;
    margin: 30px 0 50px 0;
}

.settings {
    margin: 0 auto;
}

.button{
    margin: 0 5px;
}

.label > p {
    margin: 0 0 10px 5px;
}

.group {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.group:nth-child(2) {
    justify-content: space-around;
}

@media (max-width: 700px) {
    .group {
        flex-direction: column;
        margin-bottom: 10px;
    }

    .label {
        margin-bottom: 10px;
    }

    .button{
        margin: 5px 0;
    }
}