.preview {
  position: relative;
  width: 100% !important;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.containerWrapper {
  display: flex;
}

.table:first-child {
  margin-right: 0.5rem;
}

.img {
  width: 100%;
  height: 100%;
  user-select: none;
}

.container {
  font-family: monospace;
  color: white;
  white-space: nowrap;
  border: 1px solid;
  border-color: var(--sub-color);
  transition: border-color var(--hover-transition);
  border-radius: 0.1rem;
}

.hidden {
  overflow: auto;
}

.hidden::-webkit-scrollbar, .hidden::-webkit-scrollbar-corner {
  width: 2em;
  height: 2em;
  background: transparent;
}

.hidden > div {
  visibility: hidden;
}

.container > div {
  width: 100%;
}

.container > img {
  border-radius: 0.1rem;
}

.inverted {
  color: var(--bg-color);
}

.inverted > div {
  background: white;
}

@media (max-width: 700px) {

  .containerWrapper {
    display: flex;
    flex-direction: column;
  }

  .table:first-child {
    display: none;
  }

}