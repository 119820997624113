.heading{
  text-align: center;
  margin: 30px 0;
}

.text {
  background-color: #fafafa;
  color: #222222;
  min-height: 800px;
  padding: 8px;
  margin: 20px 10px;
}

.editor {
  margin: 0 10px;
}

td {
  border: 1px solid #222222;
  min-width: 2em;
  padding: 0.4em;
  user-select: text;
  vertical-align: middle;
}

.copy{
  display: flex;
  margin: 20px 0;
}

.copyButton {
  margin: 0 10px;
}