@use "../../../variables" as v;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(v.$primary-color, 0.9);
  color: v.$light;
  width: 300px;
  height: 50px;
  border-radius: 10px;
  position: fixed;
  bottom: 50px;
  right: 25px;
  animation: inAnimation ease 0.3s;
}

@keyframes inAnimation {
  from {right: 10px}
  to {right: 25px}
}